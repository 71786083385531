import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * @typedef CreateVendorControlDocumentLinkParams
 * @param {object[]} controls
 * @param {object} control
 * @param {object[]} documents
 * @param {object} document
 * @param {string} organizationID
 */

/**
 * Creates a VendorControlDocumentLink between a VendorSubControl and VendorDocument
 * @param {CreateVendorControlDocumentLinkParams} input
 * @returns {Promise<void>}
 */
export const createVendorControlDocumentLink = async ({ controls, control, documents, document, organizationID }) => {
  const { createMutation } = generateGraphql("VendorControlDocumentLink", ["documentID", "controlID"]);

  if (controls && Array.isArray(controls) && controls.length > 0 && document?.id) {
    for (const item of controls) {
      if (item?.id) {
        await ItemMutation(createMutation, {
          documentID: document.id,
          controlID: item.id,
          ownerGroup: organizationID,
        }).then(() => EventLogger("VendorControlDocumentLink was successfully created."));
      }
    }
  } else if (documents && Array.isArray(documents) && documents.length > 0 && control?.id) {
    for (const item of documents) {
      if (item?.id) {
        await ItemMutation(createMutation, {
          documentID: item.id,
          controlID: control.id,
          ownerGroup: organizationID,
        }).then(() => EventLogger("VendorControlDocumentLink was successfully created."));
      }
    }
  } else if (document?.id && control?.id) {
    await ItemMutation(createMutation, {
      documentID: document.id,
      controlID: control.id,
      ownerGroup: organizationID,
    }).then(() => EventLogger("VendorControlDocumentLink was successfully created."));
  } else {
    EventLogger("Error! Invalid input for VendorControlDocumentLink function!");
  }
};
