// LayoutContext.js
import { useProfileModal } from "@application/Layout/components/LayoutHeader/components/useProfileModal";
import { useNotificationsModal } from "@application/Layout/components/LayoutHeader/hook/useNotificationsModal";
import { useUserSettingsModal } from "@application/Layout/components/LayoutHeader/hook/useUserSettingsModal";
import { UserUserInfoReturn } from "@application/Layout/hooks/types/useUserInfo";
import { Auth } from "@aws-amplify/auth";
import { useProgressEvents } from "@hooks/graphql/useProgressEvents";
import { UseModalReturnType } from "@hooks/views/useModal";
import { Theme, useTheme } from "@mui/material/styles";
import { emptyOrganizationContext, OrganizationContextValues } from "@utils/Context/OrganizationContext";
import { useUIContext } from "@utils/Context/UIContext";
import { EventLogger } from "@utils/EventLogger/EventLogger";
import { useOrganizationContext } from "@views/AdminPanel/Organizations/hooks/useOrganizationContext";
import { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useNavigationLinks, UseNavigationLinksResult } from "./useNavigationLinks";
import { useSignOutModal } from "./useSignOutModal";
import { useUserInfo } from "./useUserInfo";

export interface LayoutContextValue {
  organizationContext: OrganizationContextValues;
  navigationBarSectionModules: UseNavigationLinksResult["navigationBarSectionModules"];
  pointOfContactObject: UserUserInfoReturn["pointOfContactObject"] | null;
  roleAccountType: UseNavigationLinksResult["roleAccountType"] | null;
  routes: UseNavigationLinksResult["routes"];
  theme: Theme | null;
  signOut: (e: React.MouseEvent<HTMLElement>) => void | null;
  notificationModal: UseModalReturnType | null;
  profileModal: UseModalReturnType | null;
  signOutModal: ReturnType<typeof useSignOutModal> | null;
  userSettingsModal: UseModalReturnType | null;
}
const defaultLayoutContextValue: LayoutContextValue = {
  organizationContext: emptyOrganizationContext,
  navigationBarSectionModules: undefined,
  pointOfContactObject: null,
  roleAccountType: null,
  profileModal: null,
  signOutModal: null,
  userSettingsModal: null,
  routes: [],
  theme: null,
  signOut: () => undefined,
  notificationModal: null,
};
const LayoutContext = createContext<LayoutContextValue>(defaultLayoutContextValue);

export const LayoutContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const history = useHistory();
  const theme = useTheme();
  const { layoutToastsHook, onStateChange } = useUIContext();
  const organizationContext = useOrganizationContext();

  const { routes, navigationBarSectionModules, roleAccountType } = useNavigationLinks();
  const { pointOfContactObject } = useUserInfo({ routes });
  const signOutModal = useSignOutModal();
  const profileModal = useProfileModal({
    loggedInPointOfContactId: organizationContext.loggedInPointOfContactId,
    pointOfContact: pointOfContactObject,
  });
  const userSettingsModal = useUserSettingsModal();
  const notificationModal = useNotificationsModal();

  const signOut = useCallback(
    (e) => {
      e.preventDefault();
      Auth.signOut()
        .then(() => {
          onStateChange?.("signedOut", null);
          history.push("/");
          window.location.reload();
        })
        .catch((err) => {
          EventLogger("Error from Layout.js [func: signOut()]: ", err);
        });
    },
    [history, onStateChange],
  );

  useProgressEvents({ userID: organizationContext?.loggedInUserId, layoutToastsHook: layoutToastsHook });

  const value: LayoutContextValue = useMemo(
    () => ({
      navigationBarSectionModules,
      organizationContext,
      pointOfContactObject,
      roleAccountType,
      routes,
      signOut,
      theme,
      notificationModal,
      profileModal,
      signOutModal,
      userSettingsModal,
    }),
    [
      navigationBarSectionModules,
      notificationModal,
      organizationContext,
      pointOfContactObject,
      profileModal,
      roleAccountType,
      routes,
      signOut,
      signOutModal,
      theme,
      userSettingsModal,
    ],
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = (): LayoutContextValue => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutProvider");
  }
  return context;
};
