import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useModal, UseModalReturnType } from "../views/useModal";

import { AV_STATUS } from "../../enums/AV_STATUS";
import { Button } from "@mui/material";
import { EventLogger } from "../../utils/EventLogger/EventLogger";
import ModalBodyWrapper from "../../utils/GenericComponents/Modal/ModalBodyWrapper";
import { S3FileType } from "../../utils/Functions/S3Storage/types/S3FileType";
import { getSignedObjectUrlV2 } from "../../utils/Functions/S3Storage/getSignedObjectUrlV2";
import { performToastOperation } from "../../utils/Toasts/functions/toastOperation";
import { useState } from "react";
import { useUIContext } from "@utils/Context/UIContext";

export interface UseAntivirusGateParams {
  downloadFunction?: ({ bucket, key }: { bucket?: string; key?: string }) => Promise<string>;
  s3File?: S3FileType;
  avStatus: string;
  itemDisplayName: string;
  onUrlReceived: ({ url }: { url: string }) => void;
  operationName: string;
}

export interface UseAntivirusGateResult {
  isGettingDownloadLink: boolean;
  isDownloadable: boolean;
  cannotDownloadReason: () => string;
  getNotScannedReason: () => string;
  passedAntivirusCheck: boolean;
  beginDownload: ({ userWantsToDownloadUnsafeFile }: { userWantsToDownloadUnsafeFile?: boolean }) => Promise<void>;
  confirmUnsafeDownload: UseModalReturnType;
}

export const useAntivirusGate = ({
  downloadFunction,
  s3File,
  avStatus,
  itemDisplayName = "Document",
  onUrlReceived,
  operationName = "download",
}: UseAntivirusGateParams): UseAntivirusGateResult => {
  //Preparing common constants
  const capitalOperationName = operationName.charAt(0).toUpperCase() + operationName.slice(1);
  const hasBucketInformation = !!s3File;
  const hasDownloadFunction = !!downloadFunction;
  const passedAntivirusCheck = avStatus === AV_STATUS.CLEAN;

  //Context and state
  const { addToast, updateToast } = useUIContext();
  const [isGettingDownloadLink, setIsGettingDownloadLink] = useState(false);

  const cannotDownloadReason = () => {
    if (avStatus === AV_STATUS.INFECTED) {
      return `This ${itemDisplayName} is infected with a virus.`;
    } else if (!hasBucketInformation && !hasDownloadFunction) {
      return `We cannot find the ${operationName} details for this ${itemDisplayName}.`;
    } else if (isGettingDownloadLink) {
      return `We are preparing your ${operationName}, please wait...`;
    } else {
      return "An unknown issue is preventing this feature from working, please contact your administrator.";
    }
  };

  const getNotScannedReason = () => {
    if (avStatus === AV_STATUS.FILE_TOO_BIG) {
      return " because of its large size.";
    } else if (avStatus === AV_STATUS.ERROR_PROCESSING_FILE) {
      return " because we encountered an error.";
    } else {
      return ".";
    }
  };

  const isDownloadable = !isGettingDownloadLink && (hasBucketInformation || hasDownloadFunction);

  const theme = createTheme({
    palette: {
      grey: {
        // @ts-expect-error - MUI TS definition doesn't have a grey color as its custom
        main: "#d9d9d9",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  const confirmUnsafeDownload = useModal(
    "Are you sure you want to proceed?",
    <ModalBodyWrapper>
      {`This file has not been scanned by our antivirus software${getNotScannedReason()} Do you still want to ${operationName} it?`}
    </ModalBodyWrapper>,
    undefined,
    { width: "40vw" },
    <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
      <ThemeProvider theme={theme}>
        <Button
          variant={"outlined"}
          // @ts-expect-error - MUI TS definition doesn't have a grey color as its custom
          color={"grey"}
          style={{ color: "black" }}
          onClick={() => {
            confirmUnsafeDownload.setModalIsOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={async () => await beginDownload({ userWantsToDownloadUnsafeFile: true })}
        >
          {`${capitalOperationName} ${itemDisplayName}`}
        </Button>
      </ThemeProvider>
    </div>,
  );

  /**
   * Attempts to get a download url for the file, checks if allowed to do so first based on AV status and user intent
   */
  const beginDownload = async ({ userWantsToDownloadUnsafeFile = false }) => {
    if (!passedAntivirusCheck && !userWantsToDownloadUnsafeFile) {
      confirmUnsafeDownload.setModalIsOpen(true);
      return;
    } else {
      confirmUnsafeDownload.setModalIsOpen(false);
    }

    try {
      setIsGettingDownloadLink(true);

      let url: string | null = null;
      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          if (hasDownloadFunction) {
            url = await downloadFunction({
              bucket: s3File?.bucket,
              key: s3File?.key,
            });
            return;
          }

          if (hasBucketInformation) {
            url = await getSignedObjectUrlV2(s3File);
            return;
          }

          throw Error("No download function or bucket information provided");
        },
        inProgressText: `Preparing to ${operationName} ${itemDisplayName}...`,
        failedText: `Failed to ${operationName} ${itemDisplayName}.`,
        successText: `Successfully began ${operationName} of ${itemDisplayName}.`,
        iconColor: "success",
      });

      if (url && typeof onUrlReceived === "function") {
        onUrlReceived({ url });
      }
    } catch (e) {
      EventLogger("Failed to get download link", e as object);
    } finally {
      setIsGettingDownloadLink(false);
    }
  };

  return {
    isGettingDownloadLink,
    isDownloadable,
    cannotDownloadReason,
    getNotScannedReason,
    passedAntivirusCheck,
    beginDownload,
    confirmUnsafeDownload,
  };
};
