import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { modules } from "role-utils";
import { IconName } from "@components/Ions/icons/components/Icons";
import React from "react";

const MetricDashboard = React.lazy(() => import("@views/Metrics/MetricDashboard/components/MetricDashboard"));
const MetricTypeDetails = React.lazy(() => import("@views/Metrics/MetricTypes/components/MetricTypeDetails"));
const KeyPerformanceIndicatorDetails = React.lazy(
  () => import("@views/Metrics/KeyPerformanceIndicators/components/KeyPerformanceIndicatorDetails"),
);
export const metricsNavigation: NavigationModule = {
  moduleName: modules.DEVELOPER,
  sectionName: NavigationSectionName.TOOLS,
  name: "Metrics",
  path: "/metrics",
  defaultRoutePath: "",
  icon: IconName.METRICS,
  routes: [
    {
      path: "",
      name: "Metric Dashboard",
      component: MetricDashboard,
    },
    {
      path: "/metrics",
      name: "Metric Dashboard",
      component: MetricDashboard,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/metrics/:id",
      name: "Metric Details",
      component: MetricTypeDetails,
    },
    {
      path: "/keyPerformanceIndicators/:id",
      name: "Key Performance Indicator Details",
      component: KeyPerformanceIndicatorDetails,
    },
  ],
};
