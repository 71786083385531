import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import LibraryAddCheck from "@mui/icons-material/LibraryAddCheck";
import Refresh from "@mui/icons-material/Refresh";
import {
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";

import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Deselect from "@mui/icons-material/Deselect";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import OverflowToolbar from "../../../../utils/GenericComponents/CollapsibleMenu/OverflowToolbar";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { getResourceRoute } from "../../../../definitions/functions/getResourceRoute";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { routeType } from "../../../../definitions/constants/routeTypes";
import { useCheckPermissions } from "../../../permissions/useCheckPermissions/useCheckPermissions";
import { useDataGridStyles } from "./useDataGridStyles";
import { useGUID } from "../../../functional/useGUID";
import { useOpenArticle } from "../../../../views/HelpCenter/hooks/useOpenArticle";

/**
 * Handles the Toolbar for the data grid.
 * Displays default options like the Column Chooser, Filters, and Export functions.
 * Handles custom built-in options like Create, Delete, and Duplicate buttons
 * Handles custom passed in options
 * Handles custom built-in quick search bar
 *
 * @param {string} searchText - currently entered search text string
 * @param {function} requestSearch - function that handles the data filtering from the search bar
 * @param {JSX.Element} createResourceModal - a reference to the createResourceModal hook, because the form and the modal are handled separately
 * @param {JSX.Element} createResourceComponent - a reference to the createResourceComponent param. Just used to check if the button should be visible
 * @param {boolean} checkboxSelection - boolean whether checkbox selection is naturally on or off
 * @param {function} setCheckboxSelection - sets checkbox selection to on for the grid
 * @param {string[]} selectedIDs - array of selected IDs directly from the Data Grid
 * @param {function} setSelectedIDs - handler for manually setting selected IDs
 * @param {object} deleteItemsModal - a reference to the deleteItemsModal hook
 * @param {function} deleteFunction - async function for handling deletion of selected items
 * @param {object} editItemsModal - a reference to the editItemsModal hook
 * @param {string[]} options - data grid options array for checking which options are enabled
 * @param {string} module - module used for role checking
 * @param {string} helpCenterRoute - route used to open specific help center articles
 * @param {string} resource - resource used for role checking
 * @param {string} disableRoleChecking - role checking override
 * @param {string} deleteButtonText - text to display in the delete button. usually "delete", or "unlink"
 * @param {JSX.Element[]} customExports - array of custom export button components to add to the 'Export' menu in the toolbar.
 * @param {object[]} customOptions - array of custom options to add to the toolbar
 * @param {function} resetFunction - function to reset the grid
 * @param {string} typename - the type of the data in the grid
 * @param {boolean} enableSelection - whether to enable selection
 * @param {boolean} enableExport - whether to enable export
 * @param {object} duplicateItemsHook - a reference to the duplicateItemsHook
 * @param {string} createResourceButtonText - text to display in the create resource button
 * @param {object[]} columns - current column configuration for the grid
 * @param {string} persistenceUUID - UUID for the current grid configuration
 * @param {JSX.Element} customOptions[].component - component to render for each custom option
 * @returns {{toolbarProps: {clearSearch: (function(): *), onChange: (function(*): *), value: *}, Toolbar: (function({value: *, clearSearch: *, onChange: *}): *)}}
 */
export const useDataGridToolbar = ({
  searchText,
  requestSearch,
  createResourceModal,
  createResourceComponent,
  checkboxSelection,
  setCheckboxSelection,
  selectedIDs,
  setSelectedIDs,
  deleteItemsModal,
  deleteFunction,
  editItemsModal,
  options,
  module,
  helpCenterRoute,
  resource,
  disableRoleChecking,
  deleteButtonText,
  customExports = [],
  customOptions = [],
  resetFunction,
  typename,
  enableSelection,
  enableExport,
  duplicateItemsHook,
  createResourceButtonText,
  columns,
  persistenceUUID,
}) => {
  const toolbarProps = {
    value: searchText,
    onChange: (value) => requestSearch(value),
    clearSearch: () => requestSearch(""),
    searchText,
    requestSearch,
    createResourceModal,
    createResourceComponent,
    checkboxSelection,
    setCheckboxSelection,
    selectedIDs,
    setSelectedIDs,
    editItemsModal,
    deleteItemsModal,
    deleteFunction,
    options,
    module,
    helpCenterRoute,
    resource,
    disableRoleChecking,
    deleteButtonText,
    customExports,
    customOptions,
    resetFunction,
    enableSelection,
    enableExport,
    duplicateItemsHook,
    createResourceButtonText,
    columns,
    persistenceUUID,
    typename,
  };

  return {
    toolbarProps,
  };
};

export const ToolbarComponent = ({
  value,
  clearSearch,
  onChange,
  createResourceModal,
  createResourceComponent,
  checkboxSelection,
  setCheckboxSelection,
  selectedIDs,
  setSelectedIDs,
  options,
  resource,
  module,
  helpCenterRoute: initHelpCenterRoute,
  deleteFunction,
  deleteItemsModal,
  editItemsModal,
  disableRoleChecking,
  deleteButtonText,
  customOptions,
  customExports,
  resetFunction,
  enableSelection,
  enableExport,
  duplicateItemsHook,
  createResourceButtonText,
  columns,
  persistenceUUID,
  typename,
}) => {
  const classes = useDataGridStyles();

  const [guid] = useGUID();

  const permissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const helpCenterRoute = getResourceRoute({
    override: initHelpCenterRoute,
    routeType: routeType.HELP_CENTER,
    typename,
  });

  const isDuplicateButtonVisible =
    Array.isArray(options) &&
    options.includes("duplicate") &&
    permissions.resource.create &&
    checkboxSelection &&
    Array.isArray(selectedIDs) &&
    selectedIDs.length > 0;

  const isCreateButtonVisible =
    permissions.resource.create &&
    !isNullOrUndefined(createResourceModal) &&
    !isNullOrUndefined(createResourceComponent);

  const isDeleteButtonVisible =
    typeof deleteFunction === "function" &&
    Array.isArray(options) &&
    options.includes("delete") &&
    permissions.resource.delete &&
    checkboxSelection &&
    Array.isArray(selectedIDs) &&
    selectedIDs.length > 0;

  const isEditButtonVisible =
    Array.isArray(options) &&
    options.includes("edit") &&
    permissions.resource.update &&
    checkboxSelection &&
    Array.isArray(selectedIDs) &&
    selectedIDs.length > 0;

  const isResetButtonVisible = !isNullOrUndefined(resetFunction);

  const isExportButtonVisible = true;

  const isSelectButtonVisible = true;

  const isFilterButtonVisible = true;

  const isColumnButtonVisible = true;

  const isHelpButtonVisible = !isNullOrUndefined(helpCenterRoute);

  const selectButton = (
    <SelectButton
      gridID={persistenceUUID}
      classes={classes}
      checkboxSelection={checkboxSelection}
      setCheckboxSelection={setCheckboxSelection}
      selectedIDs={selectedIDs}
      setSelectedIDs={setSelectedIDs}
      key={`guid-select-button-${guid}`}
    />
  );

  const deleteButton = (
    <DeleteButton
      gridID={persistenceUUID}
      classes={classes}
      selectedIDs={selectedIDs}
      style={{ fontSize: "0.8125rem" }}
      deleteItemsModal={deleteItemsModal}
      checkboxSelection={checkboxSelection}
      deleteFunction={deleteFunction}
      options={options}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      deleteButtonText={deleteButtonText}
      key={`guid-delete-button-${guid}`}
    />
  );

  const helpButton = (
    <HelpButton
      gridID={persistenceUUID}
      classes={classes}
      key={`guid-help-button-${guid}`}
      helpCenterRoute={helpCenterRoute}
    />
  );

  const duplicateButton = (
    <DuplicateButton
      gridID={persistenceUUID}
      classes={classes}
      style={{ fontSize: "0.8125rem" }}
      selectedIDs={selectedIDs}
      checkboxSelection={checkboxSelection}
      options={options}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      duplicateItemsHook={duplicateItemsHook}
      key={`guid-duplicate-button-${guid}`}
    />
  );

  const editButton = (
    <EditButton
      gridID={persistenceUUID}
      classes={classes}
      selectedIDs={selectedIDs}
      checkboxSelection={checkboxSelection}
      options={options}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      key={`guid-edit-button-${guid}`}
      editItemsModal={editItemsModal}
      columns={columns}
    />
  );

  const resetButton = (
    <Button
      id={`data-grid-reset-button-${guid}`}
      data-testid={`data-grid-reset-button-${persistenceUUID}`}
      className={classes.toolbar}
      style={{ fontSize: "0.8125rem" }}
      startIcon={<Refresh />}
      onClick={() => resetFunction()}
      key={`guid-reset-button-${guid}`}
    >
      Refresh
    </Button>
  );

  const createText = createResourceButtonText || "Create";
  const createButton = (
    <Button
      id={`data-grid-create-button-${guid}`}
      data-testid={`data-grid-create-button-${persistenceUUID}`}
      className={classes.toolbar}
      style={{ fontSize: "0.8125rem" }}
      title={`${createText} a New Item`}
      startIcon={<Add />}
      onClick={() => createResourceModal.setModalIsOpen(true)}
      key={`guid-create-button-${guid}`}
    >
      {createText}
    </Button>
  );

  const columnsButton = (
    <GridToolbarColumnsButton id={`data-grid-columns-button-${persistenceUUID}`} key={`guid-columns-button-${guid}`} />
  );

  const filterButton = (
    <GridToolbarFilterButton id={`data-grid-filters-button-${persistenceUUID}`} key={`guid-filter-button-${guid}`} />
  );

  const GridToolbarExport = ({ csvOptions, printOptions, ...other }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
      {customExports &&
        customExports.map((exportItem, index) => {
          return React.cloneElement(exportItem, {
            key: index,
            selectedIDs: selectedIDs,
            hideMenu: other?.hideMenu,
          });
        })}
    </GridToolbarExportContainer>
  );

  const exportButton = (
    <GridToolbarExport
      id={"data-grid-exports-button"}
      key={`guid-export-button-${guid}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      printOptions={{
        hideFooter: true,
        hideToolbar: true,
      }}
    />
  );

  const getItems = () => {
    let items = [];

    // First button
    if (isCreateButtonVisible) {
      items.push(createButton);
    }

    if (isSelectButtonVisible) {
      items.push(selectButton);
    }

    // Keep these buttons close to select button because they are related
    if (isEditButtonVisible) {
      items.push(editButton);
    }

    if (isDeleteButtonVisible) {
      items.push(deleteButton);
    }

    if (isDuplicateButtonVisible) {
      items.push(duplicateButton);
    }

    if (isResetButtonVisible) {
      items.push(resetButton);
    }

    if (isExportButtonVisible) {
      items.push(exportButton);
    }

    if (isFilterButtonVisible) {
      items.push(filterButton);
    }

    if (isColumnButtonVisible) {
      items.push(columnsButton);
    }

    if (isHelpButtonVisible) {
      items.push(helpButton);
    }

    // Handle custom option buttons
    if (!isNullOrUndefined(customOptions) && Array.isArray(customOptions)) {
      const itemsAtStart = [];
      const itemsAtEnd = [];

      for (const option of customOptions) {
        // Handle options as an object like { component: <Component /> }
        if (typeof option === "object" && option.hasOwnProperty("component")) {
          if (option.hasOwnProperty("layoutPriority") && option?.layoutPriority < 1000) {
            itemsAtStart.push(
              React.cloneElement(option.component, {
                key: `guid-custom-option-${guid}`,
                selectedIDs,
              }),
            );
          } else {
            itemsAtEnd.push(
              React.cloneElement(option.component, {
                key: `guid-custom-option-${guid}`,
                selectedIDs,
              }),
            );
          }
        }
        // Handle components directly
        else {
          itemsAtEnd.push(
            React.cloneElement(option, {
              key: `guid-custom-option-${guid}`,
              selectedIDs,
            }),
          );
        }
      }

      items = [...itemsAtStart, ...items, ...itemsAtEnd];
    }

    return items;
  };

  const toolbarItems = useMemo(
    () => getItems(),
    [
      isCreateButtonVisible,
      isDuplicateButtonVisible,
      isEditButtonVisible,
      isDeleteButtonVisible,
      isResetButtonVisible,
      isSelectButtonVisible,
      isExportButtonVisible,
      isFilterButtonVisible,
      isColumnButtonVisible,
      isHelpButtonVisible,
      customOptions,
      checkboxSelection,
    ],
  );

  return (
    <div
      className={classes.root}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {createResourceModal?.modal}
      {duplicateItemsHook?.modal}
      {deleteItemsModal?.modal}
      <div
        id={`data-grid-options-buttons-${persistenceUUID}`}
        style={{
          flex: 6,
          overflowX: "hidden",
        }}
      >
        <OverflowToolbar items={toolbarItems} />
      </div>
      <TextField
        style={{
          flex: 4,
        }}
        id={`grid-search-input-${persistenceUUID || "default"}`}
        data-testid={`grid-search-input-${persistenceUUID || "default"}`}
        variant="standard"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

/**
 * A 'Select' button for the toolbar.
 * Toggles checkbox selection for the DataGrid.
 *
 * Once multiple items are selected, other options like "bulk delete" and "bulk edit" may become enabled
 * @param {object} classes - The classes object from the DataGrid
 * @param {boolean} checkboxSelection - whether the checkbox is checked
 * @param {function} setCheckboxSelection - A function to set the checkbox selection
 * @param {object[]} selectedIDs - The IDs of the selected items
 * @param {function} setSelectedIDs - A function to set the selected IDs
 * @returns {JSX.Element}
 */
const SelectButton = ({ gridID, classes, checkboxSelection, setCheckboxSelection, setSelectedIDs }) => {
  const handleClick = () => {
    if (checkboxSelection) {
      setSelectedIDs([]);
      setCheckboxSelection(false);
    } else {
      setCheckboxSelection(true);
    }
  };

  const [guid] = useGUID();

  return (
    <Button
      id={`data-grid-select-button-${guid}`}
      data-testid={`data-grid-select-button-${gridID}`}
      style={{ fontSize: "0.8125rem" }}
      className={classes.toolbar}
      startIcon={checkboxSelection ? <Deselect /> : <LibraryAddCheck />}
      onClick={handleClick}
      title={checkboxSelection ? "Hide Checkboxes" : "Display Checkboxes"}
    >
      {checkboxSelection ? "Unselect" : "Select"}
    </Button>
  );
};

/**
 * A 'Delete' button for the Toolbar.
 * @param {object} classes - The classes object from the Material UI theme
 * @param {object[]} selectedIDs - The IDs of the selected items
 * @param {object} deleteItemsModal - The delete items modal object
 * @param {string} deleteButtonText - The text to display on the delete button
 * @returns {JSX.Element}
 */
const DeleteButton = ({ gridID, classes = {}, deleteItemsModal, deleteButtonText = "Delete" }) => {
  const [guid] = useGUID();

  return (
    <Button
      id={`data-grid-delete-button-${guid}`}
      data-testid={`data-grid-delete-button-${gridID}`}
      style={{ fontSize: "0.8125rem" }}
      className={classes.toolbar}
      startIcon={<Delete />}
      onClick={() => deleteItemsModal.setModalIsOpen(true)}
    >
      {deleteButtonText}
    </Button>
  );
};

/**
 * A 'Duplicate' button for the Toolbar
 * @param {object} classes - The classes object from the Material UI theme
 * @param {object} duplicateItemsHook - reference to the duplicate items modal that will be opened on button click
 * @returns {JSX.Element}
 */
const DuplicateButton = ({ gridID, classes, duplicateItemsHook }) => {
  const [guid] = useGUID();

  return (
    <Button
      style={{ fontSize: "0.8125rem" }}
      id={`data-grid-duplicate-button-${guid}`}
      data-testid={`data-grid-duplicate-button-${gridID}`}
      className={classes.toolbar}
      startIcon={<ContentCopyIcon />}
      onClick={() => duplicateItemsHook.setModalIsOpen(true)}
    >
      Duplicate
    </Button>
  );
};
const HelpButton = ({ gridID, classes, helpCenterRoute }) => {
  const openArticle = useOpenArticle({ helpCenterRoute });
  const [guid] = useGUID();

  return (
    <Button
      style={{ fontSize: "0.8125rem" }}
      id={`data-grid-help-button-${guid}`}
      data-testid={`data-grid-help-button-${gridID}`}
      className={classes.toolbar}
      startIcon={<HelpOutlineOutlinedIcon />}
      onClick={() => openArticle(helpCenterRoute)}
      helpCenterRoute={helpCenterRoute}
    >
      Help
    </Button>
  );
};

/**
 * A 'Bulk Edit' button for the toolbar
 * @param {object} classes - The classes object from the theme
 * @param {object} editItemsModal - reference to the edit modal to open on button click
 * @returns {JSX.Element}
 */
const EditButton = ({ gridID, classes, editItemsModal }) => {
  const [guid] = useGUID();

  return (
    <Button
      id={`data-grid-edit-button-${guid}`}
      data-testid={`data-grid-edit-button-${gridID}`}
      style={{ fontSize: "0.8125rem" }}
      className={classes.toolbar}
      startIcon={<EditIcon />}
      onClick={() => editItemsModal.setModalIsOpen(true)}
    >
      {editItemsModal.modal}
      Edit
    </Button>
  );
};
