import { ASSESSMENT_STATUS, ASSESSMENT_TYPE } from "../../../../typedefs/Testing/Assessment/Assessment";
import { modules, resources } from "@rivial-security/role-utils";

import AddSingleAssessment from "../components/AddSingleAssessment";
import AssessmentDetails from "../components/AssessmentDetails";
import AssessmentPointOfContact from "../customFields/AssessmentPointOfContact";
import DateFilterMenu from "../../../../utils/FilterMenus/DateFilterMenu";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import React from "react";
import { deleteAssessment } from "../functions/deleteAssessment";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { listAssessmentsByOwnerGroup } from "../graphql/__assessmentGQL";
import { pointOfContactName } from "../../../../utils/Functions/pointOfContactName";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Display Assessment grid hook
 * @param {string} organizationID - Organization ID
 * @param {object[]} fields - Fields to display in grid
 * @param {object} props - Props passed to component
 * @return {object} {{data: *[], setData: (value: (((prevState: *[]) => *[]) | *[])) => void, display: JSX.Element}}
 */
export const useAssessmentDataGrid = ({ organizationID, fields, ...props }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.ASSESSMENT;

  const gridFields = fields || [
    {
      name: "name",
      flex: 1,
      bulkEdit: true,
    },
    {
      name: "assessmentDate",
      flex: 1,
      inputType: GENERIC_FIELD_TYPES.DATE,
      disablePropagation: true,
      inputConfig: {
        maxDate: new Date(),
      },
      filterTemplate: (props) => <DateFilterMenu props={props} />,
      sort: {
        direction: "desc",
        priority: 1,
      },
      disableEdits: true,
      type: "date",
    },
    {
      name: "pointOfContact",
      flex: 1,
      component: <AssessmentPointOfContact isJson={false} />,
      valueGetter: (item) => pointOfContactName(item.value),
      searchKeys: ["pointOfContact.firstName", "pointOfContact.lastName"],
      // bulkEdit: true,
    },
    {
      name: "penTester",
      flex: 1,
      bulkEdit: true,
    },
    {
      name: "type",
      flex: 1,
      filter: {
        type: "CheckBox",
      },
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({ ENUM: ASSESSMENT_TYPE }),
      },
      bulkEdit: true,
      valueOptions: Object.values(ASSESSMENT_TYPE),
      type: "singleSelect",
    },
    {
      name: "status",
      flex: 1,
      filter: {
        type: "CheckBox",
      },
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({ ENUM: ASSESSMENT_STATUS }),
      },
      bulkEdit: true,
      valueOptions: Object.values(ASSESSMENT_STATUS),
      type: "singleSelect",
    },
  ];

  const { updateMutation } = generateGraphql("Assessment", ["name", "type", "penTester", "status"]);

  return useDataGrid({
    module,
    resource,
    title: "Security Assessments",
    headerIcon: "icon-badge",
    fields: gridFields,
    route: "#/vulnerabilities/assessments/",
    detailsComponent: <AssessmentDetails />,
    createResourceComponent: <AddSingleAssessment />,
    createResourceComponentWidth: "80vw",
    query: listAssessmentsByOwnerGroup,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    updateMutation,
    typename: "Assessment",
    persistenceUUID: "data-grid-assessments",
    deleteFunction: deleteAssessment,
    deleteOptions: {
      deleteVulnerabilities: {
        defaultValue: false,
        label: "Delete Vulnerabilities",
        tooltip:
          "Delete all Vulnerabilities associated with this Assessment. If a Vulnerability is associated with another Assessment, it will not be deleted.",
        inputType: "switch",
      },
      deleteTargets: {
        defaultValue: false,
        label: "Delete Targets",
        tooltip:
          "Delete all targets associated with this assessment. If a target is associated with another assessment, it will not be deleted.",
        inputType: "switch",
      },
    },
    organizationID,
    options: ["details", "delete", "edit"],
    ...props,
  });
};
