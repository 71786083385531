import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { DeleteVendorCertificationControlLink } from "./DeleteVendorCertificationControlLink";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 *
 * @param vendorCertification {object}
 * @constructor
 */

export const DeleteVendorCertification = async (vendorCertification) => {
  if (!vendorCertification || !vendorCertification.id) {
    EventLogger("Error! Invalid input for DeleteVendorCertification function");
    return null;
  }

  const { getQuery } = generateGraphql("VendorCertification", ["controlLinks"], {
    controlLinks: `(limit: 100) {
    items {
       id
      }
      nextToken
    }`,
  });

  const certification = await QueryGetItem({
    query: getQuery,
    itemId: vendorCertification && vendorCertification.id,
  });

  if (
    certification &&
    certification.controlLinks &&
    certification.controlLinks.items &&
    Array.isArray(certification.controlLinks.items)
  ) {
    for (const link of certification.controlLinks.items) {
      await DeleteVendorCertificationControlLink(link);
    }
  }

  const { deleteMutation } = generateGraphql("VendorCertification", []);

  await ItemMutation(deleteMutation, {
    id: certification && certification.id,
  });
};
