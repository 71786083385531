import AWS from "aws-sdk";
import { GetOrgIamCredentials } from "./GetOrgIamCredentials";
import { EventLogger } from "../../EventLogger/EventLogger";
import awsmobile from "../../../aws-exports";
import { S3_BUCKET_NAME } from "../../../env-config";
import { Auth } from "@aws-amplify/auth";

/**
 * Upload large files to S3 using multipart upload
 * @param {object} file - file to upload
 * @param {string} bucket - bucket name to upload
 * @param {string} key - object key to upload
 * @param {string} organizationID - organization ID to upload
 * @param {function} uploadProgress - progress callback
 * @returns {Promise<void>}
 */
export const s3MultiPartUpload = async ({ file, bucket, key, organizationID, uploadProgress }) => {
  if (!file) {
    EventLogger("[s3MultiPartUpload.js] No file provided");
    return;
  }

  if (!bucket) {
    EventLogger("[s3MultiPartUpload.js] No bucket provided");
    return;
  }

  if (!key) {
    EventLogger("[s3MultiPartUpload.js] No key provided");
    return;
  }

  if (!organizationID) {
    EventLogger("[s3MultiPartUpload.js] No organizationID provided");
    return;
  }

  // Get credentials for S3 bucket
  const isDefaultBucket = bucket === S3_BUCKET_NAME;
  let credentials;
  if (isDefaultBucket) {
    credentials = await Auth.currentCredentials();
  } else {
    credentials = await GetOrgIamCredentials({ organizationID });
  }

  if (!credentials) {
    throw new Error("[s3MultiPartUpload.js] No credentials available for S3 bucket");
  }

  const s3 = new AWS.S3({
    credentials,
    region: awsmobile.aws_project_region,
  });

  const params = {
    Bucket: bucket,
    Key: key, // e.g., 'uploads/myfile.jpg'
    ContentType: file?.type,
  };

  const data = await s3.createMultipartUpload(params).promise();

  if (!data?.UploadId) {
    throw new Error("[s3MultiPartUpload.js] UploadId is not available");
  }

  const uploadId = data?.UploadId;
  const partSize = 5 * 1024 * 1024; // 5MB

  // Calculate the number of parts
  const numParts = Math.ceil(file?.size / partSize);
  let completedParts = 0;
  const uploadPromises = [];

  // Upload each part using a loop
  for (let partNumber = 1; partNumber <= numParts; partNumber++) {
    const start = (partNumber - 1) * partSize;
    const end = Math.min(start + partSize, file.size);

    const partParams = {
      Body: file.slice(start, end),
      Bucket: bucket,
      Key: key,
      PartNumber: partNumber,
      UploadId: uploadId,
    };

    uploadPromises.push(
      s3
        .uploadPart(partParams)
        .promise()
        .then((data) => {
          completedParts++;
          EventLogger(`Part ${completedParts} uploaded for file: ${file?.name}`);

          // Call progress callback
          if (typeof uploadProgress === "function") {
            uploadProgress(Math.round((completedParts / numParts) * 100));
          }
          return data;
        })
        .catch((err) => {
          throw new Error(`[s3MultiPartUpload.js] Error uploading part ${partNumber}: ${JSON.stringify(err)}`);
        }),
    );
  }

  const uploadResults = await Promise.all(uploadPromises);

  // Complete the multipart upload when all parts are uploaded
  const completeParams = {
    Bucket: bucket,
    Key: key,
    MultipartUpload: {
      Parts: uploadResults.map(({ ETag }, i) => ({
        ETag,
        PartNumber: i + 1,
      })),
    },
    UploadId: uploadId,
  };

  await s3
    .completeMultipartUpload(completeParams)
    .promise()
    .then((completeData) => {
      EventLogger(`Multipart upload completed: ${JSON.stringify(completeData)}`);
    })
    .catch((completeErr) => {
      throw new Error(`Error completing multipart upload: ${JSON.stringify(completeErr)}`);
    });

  return Promise.resolve();
};
