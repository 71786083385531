import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";

import { IconName } from "@components/Ions/icons/components/Icons";
import React from "react";
import i18next from "../../../../i18n/i18n";
import { modules } from "@rivial-security/role-utils";

const ComplianceOverviewPage = React.lazy(() => import("@components/Pages/Compliance/ComplianceOverviewPage"));
const EvidencePage = React.lazy(() => import("@components/Pages/Compliance/EvidencePage"));
const ControlsPage = React.lazy(() => import("@components/Pages/Compliance/ControlsPage"));

export const complianceNavigation: NavigationModule = {
  moduleName: modules.COMPLIANCE,
  sectionName: NavigationSectionName.SERVICES,
  name: i18next.t("compliance:compliance"),
  path: "/compliance",
  deprecatedPaths: ["/continuous_compliance"],
  defaultRoutePath: "/overview",
  icon: IconName.COMPLIANCE,
  routes: [
    {
      path: "/overview",
      name: i18next.t("compliance:overview"),
      component: ComplianceOverviewPage,
      navigationBar: {
        name: i18next.t("compliance:overview"),
      },
    },
    {
      path: "/evidence",
      name: i18next.t("compliance:evidence"),
      component: EvidencePage,
    },
    {
      path: "/controls",
      name: i18next.t("compliance:controls"),
      component: ControlsPage,
    },
  ],
};
