import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { v4 as uuid } from "uuid";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import awsmobile from "../../../../aws-exports";
import { fargateApi } from "../../../../utils/Functions/FargateApi/fargateApi";
import { createJob } from "../../../OrganizationManager/Jobs/functions/createJob";
import { generateS3ObjectKey } from "../../../../utils/Functions/S3Storage/generateS3ObjectKey";
import { s3MultiPartUpload } from "../../../../utils/Functions/S3Storage/s3MultiPartUpload";
import { scannerFields } from "@rivial-security/integration-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description Wrapper function for uploading security vulnerability
 * @param {string} organizationID - the organization ID
 * @param {Object[]} files - array of files to upload
 * @param {string} assessmentId - assessment ID
 * @param {string} scanner - the scanner that generated the vulnerability
 * @param {object} columnMap - column mapping for the scanner fields name of header to name of field (as defined by scanner display fields)
 * @param {function} callback - called when upload is finished
 * @param {function} onprogress - returns a progress status of the file upload
 * @param {object} reportConfig - report configuration object
 * @param {string} sentryTrace - Sentry trace for tracing calls from frontend to backend
 * @param {function} addToast - function to add a toast notification
 * @param {function} onJobStarted - callback function to get job
 * @return {Promise<null|Array>}
 * @constructor
 */
export const uploadVulnerabilities = async ({
  organizationID,
  files,
  assessmentId,
  scanner,
  columnMap,
  callback,
  onprogress,
  reportConfig,
  sentryTrace,
  addToast,
  onJobStarted,
}) => {
  if (
    !organizationID &&
    typeof organizationID !== "string" &&
    !files &&
    !Array.isArray(files) &&
    !assessmentId &&
    typeof assessmentId !== "string" &&
    !scanner
  ) {
    EventLogger("Invalid input for uploadVulnerabilities function");
    return null;
  }

  //Create a job to use for tracking import progress
  const job = await createJob({
    name: `Import Assessment Job ${new Date().toLocaleString()}`,
    status: "started",
    ownerGroup: organizationID,
    type: "import",
    sourceID: assessmentId,
  });

  /**
   * Get a job to track progress
   */
  if (typeof onJobStarted === "function") onJobStarted({ job });

  const getOrganizationS3BucketName = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        s3BucketName
      }
    }
  `;

  const org = await GetQuery({
    query: getOrganizationS3BucketName,
    variables: { id: organizationID },
  });

  const bucketName = org?.s3BucketName;

  if (!bucketName) return;

  EventLogger("Sending request to uploadSecurityFindings.");

  const uploadedS3Files = [];

  for (const file of files) {
    const fileName = file?.name ? file?.name : `${uuid()}.csv`;

    const objectKey = generateS3ObjectKey(`security-assessments/${assessmentId}/csv/${scanner}/${fileName}`);

    try {
      /**
       * Upload file to S3
       */
      await s3MultiPartUpload({
        file,
        bucket: bucketName,
        key: objectKey,
        organizationID,
        uploadProgress: (progress) => onprogress && onprogress(progress),
      }).then(() => {
        uploadedS3Files.push({
          bucketName,
          objectKey,
        });
      });

      /**
       * Save files as assessment documents
       */
      const { createMutation: createDocumentMutation } = generateGraphql(
        "Document",
        ["name", "owner", "visibility", "createdAt", "file"],
        {
          file: "{ bucket region key }",
        },
      );

      await ItemMutation(createDocumentMutation, {
        name: fileName,
        file: {
          region: awsmobile.aws_project_region,
          bucket: bucketName,
          key: objectKey,
        },
        ownerGroup: organizationID,
        documentAssessmentId: assessmentId,
      });
    } catch (error) {
      if (typeof addToast === "function") {
        addToast({
          header: `Failed to upload "${file?.name}" file. Error: ${error?.message}`,
          icon: "danger",
        });
      }
      EventLogger(`Cannot upload file ${fileName} to S3. ${error}`);
    }
  }

  if (Array.isArray(uploadedS3Files) && uploadedS3Files.length > 0) {
    const selectedScannerFields = scannerFields?.[scanner];
    const resourceNameColumnMapping = {};
    for (const mappedFieldName in columnMap) {
      const resourceFieldName = selectedScannerFields?.find(
        (field) => field.displayName === columnMap[mappedFieldName],
      )?.resourceFieldName;
      if (resourceFieldName) {
        resourceNameColumnMapping[resourceFieldName] = mappedFieldName;
      }
    }

    /**
     * Input for api
     */
    const input = {
      route: "import/vulnerabilities/assessment",
      organizationID,
      uploadedS3Files,
      scannerConfig: {
        scannerName: scanner,
        columnMapping: resourceNameColumnMapping,
      },
      assessmentID: assessmentId,
      reportConfig,
      job,
      sentryTrace,
      useQueue: true,
    };

    await fargateApi({ input });
  } else {
    EventLogger("[uploadVulnerabilities.js] No files were uploaded.");
  }

  if (typeof callback === "function") {
    callback();
  }
};
